var isRowInViewPort = require("./task_row_checker");

module.exports = function isInViewPort(item, view, viewport){

	if(!isRowInViewPort(item, view, viewport)){
		return false;
	}

	if(!item.start_date || !item.end_date){
		return false;
	}
	var left = view.posFromDate(item.start_date);
	var right = view.posFromDate(item.end_date);
	
	if(left > right){
		// rtl
		var tmp = right;
		right = left;
		left = tmp;
	}

	left -= 200; // add buffer for custom elements
	right += 200;

	if(viewport.x > right || viewport.x_end < left){
		return false;
	}
		
	return true;
};