var smartRender = require("./smart_render_wrapper");
var isBarInViewPort = require("./viewport/task_bar_checker");
var createBaseBarRender = require("./task_bar_render");
var isLegacyRender = require("./is_legacy_smart_render");
module.exports = function createTaskRenderer(gantt){
	var defaultRender = createBaseBarRender(gantt);

	return smartRender(
		defaultRender,
		function(){},
		isBarInViewPort,
		gantt.config.smart_rendering && !isLegacyRender(gantt)
	);
};