var helpers = require("../../../utils/helpers");
var smartRender = require("./smart_render_wrapper");
var isRowInViewPort = require("./viewport/task_row_checker");
var isLegacyRender = require("./is_legacy_smart_render");
function createGridLineRender(gantt){

	function isInViewPort(item, view, viewport){
		if(gantt.$keyboardNavigation && gantt.$keyboardNavigation.dispatcher.isTaskFocused(item.id)){
			return true;
		}

		return isRowInViewPort(item, view, viewport);
	}

	function _render_grid_item(item, view, viewport) {
		var columns = view.getGridColumns();
		var config = view.$getConfig(),
			templates = view.$getTemplates();

		var store = view.$config.rowStore;

		if(config.rtl){
			columns = columns.reverse();
		}

		var cells = [];
		var has_child;
		for (var i = 0; i < columns.length; i++) {
			var last = i == columns.length - 1;
			var col = columns[i];
			var cell;

			var value;
			var textValue;
			if (col.name == "add") {
				var aria = gantt._waiAria.gridAddButtonAttrString(col);

				value = "<div " + aria + " class='gantt_add'></div>";
				textValue = "";
			} else {
				if (col.template)
					value = col.template(item);
				else
					value = item[col.name];

				if (helpers.isDate(value))
					value = templates.date_grid(value, item);

				if (value === null || value === undefined) {
					value = "";
				}

				textValue = value;
				value = "<div class='gantt_tree_content'>" + value + "</div>";
			}
			var css = "gantt_cell" + (last ? " gantt_last_cell" : "");

			var tree = [];
			if (col.tree) {
				for (var j = 0; j < item.$level; j++)
					tree.push(templates.grid_indent(item));

				has_child = store.hasChild(item.id) && !(gantt.isSplitTask(item) && !gantt.config.open_split_tasks);
				if (has_child) {
					tree.push(templates.grid_open(item));
					tree.push(templates.grid_folder(item));
				} else {
					tree.push(templates.grid_blank(item));
					tree.push(templates.grid_file(item));
				}
			}
			var style = "width:" + (col.width - (last ? 1 : 0)) + "px;";
			if (this.defined(col.align))
				style += "text-align:" + col.align + ";";

			var aria = gantt._waiAria.gridCellAttrString(col, textValue);

			tree.push(value);
			if(config.rtl){
				tree = tree.reverse();
			}
			cell = "<div class='" + css + "' data-column-index='"+i+"' data-column-name='"+col.name+"' style='" + style + "' " + aria + ">" + tree.join("") + "</div>";
			cells.push(cell);
		}
		var css = gantt.getGlobalTaskIndex(item.id) % 2 === 0 ? "" : " odd";
		css += (item.$transparent) ? " gantt_transparent" : "";

		css += (item.$dataprocessor_class ? " " + item.$dataprocessor_class : "");

		if (templates.grid_row_class) {
			var css_template = templates.grid_row_class.call(gantt, item.start_date, item.end_date, item);
			if (css_template)
				css += " " + css_template;
		}

		if(store.isSelected(item.id)) {
			css += " gantt_selected";
		}

		var el = document.createElement("div");
		el.className = "gantt_row" + css + " gantt_row_" + gantt.getTaskType(item.type);
		var height = view.getItemHeight();
		el.style.height = height + "px";
		el.style.lineHeight = height + "px";

		if(config.smart_rendering){
			el.style.position = "absolute";
			el.style.left = "0px";
			el.style.top = view.getItemTop(item.id) + "px";
		}

		if(view.$config.item_attribute){
			el.setAttribute(view.$config.item_attribute, item.id);
		}

		gantt._waiAria.taskRowAttr(item, el);

		el.innerHTML = cells.join("");
		return el;
	}

	//return _render_grid_item;

	return smartRender(
		_render_grid_item,
		function(){},
		isInViewPort,
		gantt.config.smart_rendering && !isLegacyRender(gantt)
	);
}

module.exports = createGridLineRender;

