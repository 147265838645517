module.exports = function wrapSmartRender(renderOne, updateOne, isInViewPort, smartRendering){
	if(!smartRendering){
		return renderOne;
	}else{
		return {
			render: function(item, view, viewport){
				if(!isInViewPort.call(this, item, view, viewport)){
					return null;
				}
				return renderOne.call(this, item, view, viewport);
			},
			update: function(item, view, engine, viewport){
				if(!isInViewPort.call(this, item, view, viewport)){
					engine.hide(item.id);
				}else if(engine.rendered[item.id]){
					updateOne.call(this, item, view, engine, viewport);
					engine.restore(item);
				}else{
					renderOne.call(this, item, view, viewport);
				}
			}
		};
	}


};