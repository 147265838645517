require("css/skins/terrace.less");

function DHXGantt(){
	this.constants = require("./../constants");
	this.version = VERSION;
	this.license = LICENSE;
	this.templates = {};
	this.ext = {};
	this.keys = {
		edit_save: this.constants.KEY_CODES.ENTER,
		edit_cancel: this.constants.KEY_CODES.ESC
	};
}

module.exports = function() {
	// use a named constructor to make gantt instance discoverable in heap snapshots
	var gantt = new DHXGantt();

	require("./common/import")(gantt);

	gantt.$services = gantt.$inject(require("./common/services"));
	gantt.config = gantt.$inject(require("./common/config"));
	gantt.ajax =  require("./common/ajax")(gantt);
	gantt.date = require("./common/date")(gantt);
	var dnd = require("./common/dnd")(gantt);
	gantt.$services.setService("dnd", function(){return dnd;});

	gantt.$services.setService("config", function () {
		return gantt.config;
	});
	gantt.$services.setService("date", function () {
		return gantt.date;
	});
	gantt.$services.setService("locale", function () {
		return gantt.locale;
	});
	gantt.$services.setService("templates", function () {
		return gantt.templates;
	});

	var templatesLoader = require("./common/templates")(gantt);
	gantt.$services.setService("templateLoader", function () {
		return templatesLoader;
	});

	var eventable = require("../utils/eventable");
	eventable(gantt);

	var StateService = require("./common/state");
	var stateService = new StateService();

	stateService.registerProvider("global", function () {
		var res = {
			min_date: gantt._min_date,
			max_date: gantt._max_date,
			selected_task: null
		};

		// do not throw error if getState called from non-initialized gantt
		if(gantt.$data && gantt.$data.tasksStore){
			res.selected_task = gantt.$data.tasksStore.getSelectedId();
		}
		return res;
	});
	gantt.getState = stateService.getState;
	gantt.$services.setService("state", function () {
		return stateService;
	});

	var utils = require("../utils/utils");
	utils.mixin(gantt, utils);

	gantt.Promise = require("../utils/promise");
	gantt.env = require("../utils/env");

	var domHelpers = require("../utils/dom_helpers");
	gantt.utils = {
		dom: {
			getNodePosition: domHelpers.getNodePosition,
			getRelativeEventPosition: domHelpers.getRelativeEventPosition,
			isChildOf: domHelpers.isChildOf,
			hasClass: domHelpers.hasClass,
			closest: domHelpers.closest
		}
	};

	var domEvents = require("../utils/dom_event_scope")();
	gantt.event = domEvents.attach;
	gantt.eventRemove = domEvents.detach;
	gantt._eventRemoveAll = domEvents.detachAll;
	gantt._createDomEventScope = domEvents.extend;

	utils.mixin(gantt, require("./message")(gantt));
	var uiApi = require("./ui/index").init(gantt);
	gantt.$ui = uiApi.factory;
	gantt.$ui.layers = uiApi.render;
	gantt.$mouseEvents = uiApi.mouseEvents;
	gantt.$services.setService("mouseEvents", function () {
		return gantt.$mouseEvents;
	});
	gantt.mixin(gantt, uiApi.layersApi);

	require("./data_task_layers")(gantt);

	gantt.$services.setService("layers", function () {
		return uiApi.layersService;
	});

	var createLayoutFacade = require("./facades/layout");
	gantt.mixin(gantt, createLayoutFacade());

	require("./datastore/datastore_hooks")(gantt);

	var DataProcessor = require("./dataprocessor");
	gantt.dataProcessor = DataProcessor.DEPRECATED_api;
	gantt.createDataProcessor = DataProcessor.createDataProcessor;

	require("./plugins")(gantt);

	require("./dynamic_loading")(gantt);
	require("./grid_column_api")(gantt);
	require("./wai_aria")(gantt);
	require("./tasks")(gantt);
	require("./load")(gantt);
	require("./worktime/work_time")(gantt);
	require("./data")(gantt);
	require("./lightbox")(gantt);
	require("./lightbox_optional_time")(gantt);
	require("./data_task_types")(gantt);
	require("./cached_functions")(gantt);
	require("./skin")(gantt);
	require("../css/skins/skyblue")(gantt);
	require("../css/skins/meadow")(gantt);
	require("../css/skins/terrace")(gantt);
	require("../css/skins/broadway")(gantt);
	require("../css/skins/material")(gantt);
	require("../css/skins/contrast_black")(gantt);
	require("../css/skins/contrast_white")(gantt);
	require("./touch")(gantt);
	require("../locale")(gantt);
	require("./gantt_core")(gantt);
	require("./destructor")(gantt);

	return gantt;
};