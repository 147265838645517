var helpers = require("../../utils/helpers");
var smartRender = require("../ui/render/smart_render_wrapper");

function createResourceMethods(gantt){

	var resourceTaskCache = {};

	gantt.$data.tasksStore.attachEvent("onStoreUpdated", function(){
		resourceTaskCache = {};
	});

	function getTaskBy(propertyName, propertyValue) {
		if (typeof propertyName == "function") {
			return filterResourceTasks(propertyName);
		} else {
			if (helpers.isArray(propertyValue)) {
				return getResourceTasks(propertyName, propertyValue);
			} else {
				return getResourceTasks(propertyName, [propertyValue]);
			}
		}
	}

	function filterResourceTasks(filter) {
		var res = [];
		gantt.eachTask(function (task) {
			if (filter(task)) {
				res.push(task);
			}
		});
		return res;
	}

	function getResourceTasks(property, resourceIds) {
		var res;

		var cacheKey = resourceIds.join("_") + "_" + property;

		var resourceHash = {};
		for (var i = 0; i < resourceIds.length; i++) {
			resourceHash[resourceIds[i]] = true;
		}

		if (!resourceTaskCache[cacheKey]) {
			res = resourceTaskCache[cacheKey] = [];
			gantt.eachTask(function (task) {
				if (task.type == gantt.config.types.project)
					return;

				var resourceValue;
				if (!helpers.isArray(task[property])) {
					resourceValue = [task[property]];
				} else {
					resourceValue = task[property];
				}

				helpers.forEach(resourceValue, function(value) {
					if(value && (resourceHash[value]  || resourceHash[value.resource_id])) {
						res.push(task);
					}
				});
			});
		} else {
			res = resourceTaskCache[cacheKey];
		}

		return res;
	}

	function getResourceLoad(resource, resourceProperty, scale, timeline){
		var cacheKey = resource.id + "_" + resourceProperty  + "_" + scale.unit + "_" + scale.step;
		var res;
		if (!resourceTaskCache[cacheKey]) {
			res = resourceTaskCache[cacheKey] = calculateResourceLoad(resource, resourceProperty, scale, timeline);

		} else {
			res = resourceTaskCache[cacheKey];
		}
		return res;
	}

	function calculateResourceLoad(resource, resourceProperty, scale, timeline) {

		var tasks;
		if(resource.$role == "task"){
			tasks = [];
		}else{
			tasks = getTaskBy(resourceProperty, resource.id);
		}
		var step = scale.unit;
		var timegrid = {};

		for (var i = 0; i < tasks.length; i++) {
			var task = tasks[i];

			var currDate = gantt.date[step + "_start"](new Date(task.start_date));

			while (currDate < task.end_date) {

				var date = currDate;
				currDate = gantt.date.add(currDate, 1, step);

				if (!gantt.isWorkTime({date: date, task: task, unit: step})) {
					continue;
				}

				var timestamp = date.valueOf();
				if (!timegrid[timestamp]){
					timegrid[timestamp] = [];
				}

				timegrid[timestamp].push(task);
			}
		}

		var timetable = [];
		var start, end, tasks;
		var config = timeline.$getConfig();

		for(var i = 0; i < scale.trace_x.length; i++){
			start = new Date(scale.trace_x[i]);
			end = gantt.date.add(start, 1, step);
			tasks = timegrid[start.valueOf()] || [];
			if(tasks.length || config.resource_render_empty_cells){
				timetable.push({
					start_date: start,
					end_date: end,
					tasks: tasks
				});
			}

		}

		return timetable;
	}

	function isInViewPort(item, view, viewport){
		var position = view.getItemTop(item.id);
		var height = view.getItemHeight(item);
		if(viewport.y > position + height || viewport.y_end < position){
			return false;
		}else{
			return true;
		}
	}

	function generateRenderResourceLine(){
		function renderResourceLine(resource, timeline, viewport) {
			if(!isInViewPort(resource, timeline, viewport)){
				return null;
			}
			var config = timeline.$getConfig(),
				templates = timeline.$getTemplates();
			var timetable = getResourceLoad(resource, config.resource_property, timeline.getScale(), timeline);

			var cells = [];
			for (var i = 0; i < timetable.length; i++) {

				var day = timetable[i];

				var css = templates.resource_cell_class(day.start_date, day.end_date, resource, day.tasks);
				var content = templates.resource_cell_value(day.start_date, day.end_date, resource, day.tasks);

				if (css || content){
					var sizes = timeline.getItemPosition(resource, day.start_date, day.end_date);
					var el = document.createElement('div');
					el.className = ["gantt_resource_marker", css].join(" ");

					el.style.cssText = [
						'left:' + sizes.left + 'px',
						'width:' + sizes.width + 'px',
						'height:' + (config.row_height - 1) + 'px',
						'line-height:' + (config.row_height - 1) + 'px',
						'top:' + sizes.top + 'px'
					].join(";");

					if(content)
						el.innerHTML = content;

					cells.push(el);
				}

			}

			var row = null;
			if(cells.length){
				row = document.createElement("div");
				for(var i = 0; i < cells.length; i++){
					row.appendChild(cells[i]);
				}
			}

			return row;
		}
		return smartRender(renderResourceLine, function(){}, isInViewPort);
	}
	
	function renderBar(level, start, end, timeline){
		var top = (1 - (level*1||0))*100;
		var left = timeline.posFromDate(start);
		var right = timeline.posFromDate(end);
		var element = document.createElement("div");
		element.className = "gantt_histogram_hor_bar";
		element.style.top = top + '%';
		element.style.left = left + "px";
		element.style.width = (right - left + 1) + "px";
		return element;
	}
	function renderConnection(prevLevel, nextLevel, left){
		if(prevLevel === nextLevel){
			return null;
		}

		var top = 1 - Math.max(prevLevel, nextLevel);
		var height = Math.abs(prevLevel - nextLevel);
		var element = document.createElement("div");
		element.className = "gantt_histogram_vert_bar";
		element.style.top = top*100 + "%";
		element.style.height = height*100 + "%";
		element.style.left = left + "px";

		return element;
	}

	function isColumnVisible(columnIndex, scale, viewPort){
		var width = scale.width[columnIndex];
		var cellLeftCoord = scale.left[columnIndex] - width;
		var cellRightCoord = scale.left[columnIndex] + width;
		return (width > 0 && cellLeftCoord <= viewPort.x_end && cellRightCoord >= viewPort.x);//do not render skipped columns
	}



	function generateRenderResourceHistogram(){

		var renderedHistogramCells = {};
		var renderedHistogramRows = {};

		function renderHistogramLine(capacity, timeline, maxCapacity, viewPort){
			var scale = timeline.getScale();

			var el = document.createElement("div");

			for(var i = 0; i < scale.trace_x.length; i++){
				if(!isColumnVisible(i, scale, viewPort)){
					continue;
				}

				var colStart = scale.trace_x[i];
				var colEnd = scale.trace_x[i + 1] || gantt.date.add(colStart, scale.step, scale.unit);
				var col = scale.trace_x[i].valueOf();
				var level = Math.min(capacity[col]/maxCapacity, 1) || 0;
				// do not render histogram for lines with below zero capacity, as it's reserved for folders
				if(level < 0){
					return null;
				}

				var nextLevel = Math.min(capacity[colEnd.valueOf()]/maxCapacity, 1) || 0;
				var bar = renderBar(level, colStart, colEnd, timeline);
				if(bar){
					el.appendChild(bar);
				}
				var connection = renderConnection(level, nextLevel, timeline.posFromDate(colEnd));
				if(connection){
					el.appendChild(connection);
				}

			}
			return el;
		}

		function renderResourceHistogram(resource, timeline, viewport) {
			if(!isInViewPort(resource, timeline, viewport)){
				return null;
			}
			var config = timeline.$getConfig(),
				templates = timeline.$getTemplates();
			var scale = timeline.getScale();
			var timetable = getResourceLoad(resource, config.resource_property, scale, timeline);

			var cells = [];
			var capacityMatrix = {};
			var maxCapacity = resource.capacity || timeline.$config.capacity || 24;
			renderedHistogramCells[resource.id] = {};
			renderedHistogramRows[resource.id] = null;
			for (var i = 0; i < timetable.length; i++) {

				var day = timetable[i];
				var columnIndex = scale.trace_indexes[day.start_date.valueOf()];
				if(!isColumnVisible(columnIndex, scale, viewport)){
					continue;
				}

				var css = templates.histogram_cell_class(day.start_date, day.end_date, resource, day.tasks);
				var content = templates.histogram_cell_label(day.start_date, day.end_date, resource, day.tasks);
				var fill = templates.histogram_cell_allocated(day.start_date, day.end_date, resource, day.tasks);
				var capacity = templates.histogram_cell_capacity(day.start_date, day.end_date, resource, day.tasks);
				capacityMatrix[day.start_date.valueOf()] = capacity || 0;
				if(css || content){
					var sizes = timeline.getItemPosition(resource, day.start_date, day.end_date);
					var el = document.createElement('div');
					el.className = ["gantt_histogram_cell", css].join(" ");

					el.style.cssText = [
						'left:' + sizes.left + 'px',
						'width:' + sizes.width + 'px',
						'height:' + (config.row_height - 1) + 'px',
						'line-height:' + (config.row_height - 1) + 'px',
						'top:' + (sizes.top + 1) + 'px'
					].join(";");


					if (content) {
						content = "<div class='gantt_histogram_label'>" + content +"</div>";
					}

					if (fill) {
						content = "<div class='gantt_histogram_fill' style='height:"+(Math.min(fill/maxCapacity||0, 1)*100)+"%;'></div>" + content;
					}

					if (content) {
						el.innerHTML = content;
					}

					cells.push(el);
					renderedHistogramCells[resource.id][columnIndex] = el;
				}

			}

			var row = null;
			if (cells.length) {
				row = document.createElement("div");
				for (var i = 0; i < cells.length; i++) {
					row.appendChild(cells[i]);
				}

				var capacityElement = renderHistogramLine(capacityMatrix, timeline, maxCapacity, viewport);

				if (capacityElement) {
					capacityElement.setAttribute("data-resource-id", resource.id);
					capacityElement.style.position = "absolute";
					capacityElement.style.top = (sizes.top + 1) + "px";
					capacityElement.style.height = (config.row_height - 1) + "px";
					capacityElement.style.left = 0;
					row.appendChild(capacityElement);
				}

				renderedHistogramRows[resource.id] = row;
			}

			return row;
		}

		return smartRender(renderResourceHistogram, function(){}, isInViewPort);
	}
	

	function selectAssignments(resourceId, taskId, result){
		var property = gantt.config.resource_property;
		var owners = [];
		if (gantt.getDatastore("task").exists(taskId)) {
			var task = gantt.getTask(taskId);
			owners = task[property] || [];
		}

		if (!Array.isArray(owners)) {
			owners = [owners];
		}
		for (var i = 0; i < owners.length; i++) {
			if (owners[i].resource_id == resourceId) {
				result.push({task_id: task.id, resource_id:owners[i].resource_id, value:owners[i].value});
			}
		}
	}

	function getResourceAssignments(resourceId, taskId){
		// resource assignment as an independent module:
		// {taskId:, resourceId, value}
		// TODO: probably should add a separate datastore for these
		var assignments = [];
		var property = gantt.config.resource_property;
		if(taskId !== undefined){
			selectAssignments(resourceId, taskId, assignments);
		}else{
			var tasks = gantt.getTaskBy(property, resourceId);
			tasks.forEach(function(task){
				selectAssignments(resourceId, task.id, assignments);
			});
		}
		return assignments;
	}

	return {
		renderLine: generateRenderResourceLine,
		renderHistogram: generateRenderResourceHistogram,
		filterTasks: getTaskBy,
		getResourceAssignments: getResourceAssignments
	};
}

module.exports = function(gantt){
	var methods = createResourceMethods(gantt);

	gantt.getTaskBy = methods.filterTasks;
	gantt.getResourceAssignments = methods.getResourceAssignments;
	gantt.$ui.layers.resourceRow = methods.renderLine;
	gantt.$ui.layers.resourceHistogram = methods.renderHistogram;
	gantt.config.resource_property = "owner_id";
	gantt.config.resource_store = "resource";
	gantt.config.resource_render_empty_cells = false;

	/**
	 * these are placeholder functions that should be redefined by the user
	*/
	gantt.templates.histogram_cell_class = function(start_date, end_date, resource, tasks) {};
	gantt.templates.histogram_cell_label = function(start_date, end_date, resource, tasks) {
		return tasks.length + "/3";
	};
	gantt.templates.histogram_cell_allocated = function(start_date, end_date, resource, tasks) {
		return tasks.length / 3;
	};
	gantt.templates.histogram_cell_capacity = function(start_date, end_date, resource, tasks) {
		return 0;
	};



	gantt.templates.resource_cell_class = function(start, end, resource, tasks) {
		var css = "";
		if (tasks.length <= 1) {
			css = "gantt_resource_marker_ok";
		} else {
			css = "gantt_resource_marker_overtime";
		}
		return css;
	};

	gantt.templates.resource_cell_value = function(start, end, resource, tasks) {
		return tasks.length * 8;
	};
};


